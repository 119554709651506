<template>
    <v-container fluid>
        <v-form @submit.prevent="savePartner">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>

                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'partner'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ $t('partner_profile') }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="2" class="mt-2 px-sm-6 text-center">
                                <make-avatar v-model="photo" :aspect-ratio="(1/1)"/>
                            </v-col>
                            <v-col cols="12" sm="9" md="10">
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="12">
                                        <ValidationProvider ref="partner" rules="required|min:2|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="partner" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-account"
                                                          :label="$t('partner_name')" color="primary"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="phone" rules="phone"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="phoneRaw" type="tel" v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                          :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-phone"
                                                          :label="$t('phone')" color="primary"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="email" rules="required|email"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="email" type="email"
                                                          :error-messages="errors" :error="!valid"
                                                          :disabled="loading" prepend-icon="mdi-email"
                                                          label="Email" color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="12">
                                        <ValidationProvider ref="experience" rules="numeric|min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="experience" type="numeric"
                                                          :error-messages="errors" :error="!valid"
                                                          :disabled="loading" prepend-icon="mdi-email"
                                                          :label="$t('experience')" color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>



                                </v-row>

                            </v-col>

                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="company" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="company" :disabled="loadingCompanies"
                                              :items="companyItems" :error-messages="errors"
                                              prepend-icon="mdi-home-variant-outline" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('company')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider  ref="specialty" rules="required"
                                                     v-slot="{ errors, valid }">
                                    <v-select v-model="specialty" :error="!valid" :error-messages="errors"
                                              :disabled="loadingSpecialties"
                                              :items="specialtyItems" item-text="name" item-value="id"
                                              prepend-icon="mdi-account-hard-hat" color="primary"
                                              :label="$t('specialty')"></v-select>
                                </ValidationProvider>
                            </v-col>



                            <v-col v-if="can(['administrator', 'manager'])" cols="12">
                                <ValidationProvider ref="notes" rules="min:3|max:65535"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea v-model="notes" type="text" :error-messages="errors"
                                                :disabled="loading" :label="$t('notes')" rows="5"
                                                color="primary" auto-grow clearable outlined>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-3" cols="12">
                                <h3 class="headline">{{$t('partner_access_password')}}</h3>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="password" :rules="form_required + 'min:8'"
                                                    vid="password" v-slot="{ errors, valid }">
                                    <v-text-field v-model="password" :error="!valid && create" :disabled="loading"
                                                  :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                                  :type="passwordHidden ? 'password' : 'text'" :error-messages="errors"
                                                  @click:append="() => (passwordHidden = !passwordHidden)"
                                                  prepend-icon="mdi-lock" :label="$t('enter_password')"
                                                  color="primary" clearable required></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="confirmation_password"
                                                    rules="required_if:password|confirmed:password"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="password_confirmation" :error="!valid" :disabled="loading"
                                                  :append-icon="passwordConfirmHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                                  :type="passwordHidden ? 'password' : 'text'" :error-messages="errors"
                                                  @click:append="() => (passwordConfirmHidden = !passwordConfirmHidden)"
                                                  prepend-icon="mdi-lock" :label="$t('repeat_password')"
                                                  color="primary" clearable required></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0 d-flex justify-start" cols="12" sm="6">
                                <v-switch v-model="deleted" :disabled="loading"
                                          :label="deleted ? $t('partner_deleted') : $t('partner_remove')"
                                          @change="deleted ? active = false : active" color="red"></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="active" :disabled="loading || deleted"
                                          :label="$t('partner_active')"
                                          color="primary"></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="moderated" :disabled="loading || deleted"
                                          :label="$t('partner_moderated')"
                                          color="primary"></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mask} from 'vue-the-mask'
    import MakeAvatar from '../components/MakeAvatar.vue'
    import {mapGetters} from "vuex"
    import debounce from "lodash/debounce"

    export default {
        name: 'PartnerForm',
        directives: {
            mask,
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            MakeAvatar,
        },
        inject: ['forceRerender'],
        data() {
            return {
                progress: 0,
                create: false,
                phoneRaw: null,
                partner: null,
                email: null,
                notes: null,
                photo: null,
                active: false,
                moderated: false,
                deleted: false,
                password: null,
                passwordHidden: true,
                password_confirmation: null,
                passwordConfirmHidden: true,
                loading: false,
                loadingCompanies: false,
                company: null,
                companyItems: [],
                loadingSpecialties: false,
                specialty: null,
                specialtyItems: [],

                experience: null,







            }
        },
        computed: {
            ...mapGetters(['lang', 'phoneMask']),
            phone: function () {
                return this.changePhone(this.phoneRaw)
            },
            form_required: function () {
                if (this.create) {
                    return 'required|'
                }
                return ''
            },
            selectedPole: function () {
                let find = this.roleItems.filter(item => item.id === this.role)
                console.log(find);
                if (find && find.length === 1 && find[0].code === 'courier') {
                    return 'courier'
                }
                return null
            },
        },
        watch: {
            password(val) {
                if (val && val !== this.password_confirmation) {
                    this.$refs.confirmation_password.setErrors([
                        this.$t('validations.rules.confirmed', {'_field_': this.$t('validations.fields.confirmation_password')})
                    ])
                }
            },
            password_confirmation(val) {
                if (val && val !== this.password) {
                    this.$refs.password.setErrors([
                        this.$t('validations.rules.confirmed', {'_field_': this.$t('validations.fields.password')})
                    ])
                } else {
                    this.$refs.password.reset()
                }
            },
        },
        mounted() {
            this.getCompanies();
            this.getSpecialties()
            this.checkCreate();
        },
        methods: {



            checkCreate() {
                if (this.$route.name === 'partner.create') {
                    this.create = true
                } else if (this.$route.name === 'profile' && this.$auth.check()) {
                    if (this.$auth.user().id) {
                        this.getPartner(this.$auth.user().id);
                    }
                } else {
                    if (this.$route.params.id) {
                        this.getPartner(this.$route.params.id);
                    }
                }
            },

            async getCompanies() {

                this.loadingCompanies = true
                let params = {};
                params.filter = 'search'

                await this.$http
                    .get("admin/company", {
                        params: params,
                    })
                    .then(res => {
                        this.companyItems = res.body.data;
                    })
                    .catch(err => {
                        this.companyItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_companies'))
                    })
                    .finally(end => {
                        this.loadingCompanies = false
                    })

            },
            async getSpecialties() {

                this.loadingSpecialties = true
                let params = {};
                params.filter = 'search'

                await this.$http
                    .get("admin/specialty", {
                        params: params,
                    })
                    .then(res => {
                        this.specialtyItems = res.body.data;
                    })
                    .catch(err => {
                        this.specialtyItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_specialties'))
                    })
                    .finally(end => {
                        this.loadingSpecialties = false
                    })

            },

            async getPartner(id) {
                var _this = this;
                this.progress = 0
                this.loading = true
                this.id = id
                await this.$http
                    .get(`admin/partner/${id}`, {
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.partner = res.body.data.name
                        this.phoneRaw = res.body.data.phone
                        this.email = res.body.data.email
                        this.photo = res.body.data.photo
                        this.active = res.body.data.active
                        this.moderated = res.body.data.moderated
                        this.deleted = res.body.data.deleted
                        this.notes = res.body.data.notes
                        this.company = res.body.data.company
                        this.specialty = res.body.data.specialty
                        this.experience = res.body.data.experience



                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_partner'));
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async savePartner() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.partner) {
                    formData.append('partner', this.partner)
                }
                if (this.phone) {
                    formData.append('phone', this.phone)
                }
                if (this.email) {
                    formData.append('email', this.email)
                }
                if (this.password) {
                    formData.append('password', this.password)
                }
                if (this.notes) {
                    formData.append('notes', this.notes)
                }
                if (this.active) {
                    formData.append('active', 1)
                }
                if (this.moderated) {
                    formData.append('moderated', 1)
                }
                if (this.deleted) {
                    formData.append('deleted', 1)
                }
                if (this.experience) {
                    formData.append('experience', this.experience)
                }
                if (this.company) {
                    if (this.company.id) {
                        formData.append('company', this.company.id)
                    } else {
                        formData.append('company', this.company)
                    }
                }
                if (this.specialty) {
                    if (this.specialty.id) {
                        formData.append('specialty', this.specialty.id)
                    } else {
                        formData.append('specialty', this.specialty)
                    }
                }


                if (this.photo) {
                    if (this.photo.length > 250) {
                        var mimeType = this.getMimeType(this.photo);
                        var blob = this.dataURL64toBlob(this.photo);
                        if (mimeType && blob) {
                            formData.append('photo', blob, mimeType);
                        }
                    } else {
                        formData.append('photo', this.photo);
                    }
                }
                if (this.$route.params.id) {
                    // Save
                    await this.$http
                        .put(`admin/partner/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('partner_has_been_updated'));
                            //this.forceRerender();
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('partner_has_not_been_updated'));
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/partner', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('partner_has_been_added'));
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.$router.push({
                                    name: 'partner.edit',
                                    params: {
                                        id: res.body.data.id
                                    }
                                })
                            } else {
                                this.$router.push({name: 'partner'})
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('partner_has_not_been_added'));
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            }
        }
    }
</script>
